import { Typography } from "antd";
import { FLOOR_SETUP_STATUS } from "src/constants";
import TextConstants from "src/constants/TextConstants";

const FloorStatus = ({ status }) => {
  return (
    <>
      {status === FLOOR_SETUP_STATUS.UNCONFIGURED && (
        <Typography.Text type="warning">
          {TextConstants.FacilityFloors.FloorStatus.WaitingForSettings}
        </Typography.Text>
      )}
      {status === FLOOR_SETUP_STATUS.CONFIGURING && (
        <Typography.Text style={{ color: "purple" }}>
          {TextConstants.FacilityFloors.FloorStatus.SettingUp}
        </Typography.Text>
      )}
      {status === FLOOR_SETUP_STATUS.CONFIGURED && (
        <Typography.Text type="success">
          {TextConstants.FacilityFloors.FloorStatus.AlreadySetup}
        </Typography.Text>
      )}
    </>
  );
};

export default FloorStatus;
