import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import TextConstants from "src/constants/TextConstants";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { API_ENDPOINTS } from "src/constants";

function* getFloorOperationLogsSaga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const response = yield call(
      client.get,
      API_ENDPOINTS.FLOOR_OPERATION_LOGS.replace(
        "{:facilityId}",
        facilityId
      ).replace("{:floorId}", floorId)
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFacilityFloorSaga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const response = yield call(
      client.get,
      API_ENDPOINTS.FACILITY_FLOOR.replace("{:facilityId}", facilityId).replace(
        "{:floorId}",
        floorId
      )
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFacilityFloorV2Saga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const response = yield call(
      client.get,
      API_ENDPOINTS.FACILITY_FLOOR_V2.replace(
        "{:facilityId}",
        facilityId
      ).replace("{:floorId}", floorId)
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFloorsSaga({ type, payload: facilityId }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield all([
      client.get(API_ENDPOINTS.FLOORS.replace("{:facilityId}", facilityId)),
      client.get(API_ENDPOINTS.FLOORS_V2.replace("{:facilityId}", facilityId)),
    ]);
    const v1Data = response[0];
    const v2Data = response[1];
    const v2MappedById = {};
    v2Data.forEach((floor) => {
      v2MappedById[floor["floorId"]] = floor;
    });
    v1Data.forEach((floor) => {
      const v2DataByV1Id = v2MappedById[floor["floorId"]];
      if (v2DataByV1Id) {
        floor["v2Data"] = {};
        floor["v2Data"]["bmId"] = v2DataByV1Id["bmId"];
        floor["v2Data"]["groups"] = v2DataByV1Id["groups"];
        floor["v2Data"]["lights"] = v2DataByV1Id["lights"];
      }
    });

    yield put(onSuccess(v1Data));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const facilityId = payload.facilityId;
    const response = yield call(
      client.post,
      API_ENDPOINTS.FLOORS.replace("{:facilityId}", facilityId),
      payload
    );

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.FACILITY_FLOOR.replace(
      "{:facilityId}",
      payload.facilityId
    ).replace("{:floorId}", payload.floorId)}`;
    const response = yield call(client.patch, endpoint, payload);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const endpoint = `${API_ENDPOINTS.FACILITY_FLOOR.replace(
      "{:facilityId}",
      facilityId
    ).replace("{:floorId}", floorId)}`;
    yield call(client.delete, endpoint);

    yield put(onSuccess());
    Notification.success(TextConstants.DeleteFloorModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFloorMapSettingFileURL({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.get,
      `${
        API_ENDPOINTS.GET_FLOORS_SETTINGS_FILE_PRESIGNED_URL
      }?${new URLSearchParams(payload).toString()}`
    );

    yield put(onSuccess(response));

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchFloorsSagas() {
  yield takeEvery(Actions.GET_FLOORS.REQUEST, getFloorsSaga);
  yield takeEvery(Actions.GET_FACILITY_FLOOR.REQUEST, getFacilityFloorSaga);
  yield takeEvery(
    Actions.GET_FACILITY_FLOOR_V2.REQUEST,
    getFacilityFloorV2Saga
  );
  yield takeEvery(Actions.CREATE_FLOOR.REQUEST, createFloorSaga);
  yield takeEvery(Actions.UPDATE_FLOOR.REQUEST, updateFloorSaga);
  yield takeEvery(Actions.DELETE_FLOOR.REQUEST, deleteFloorSaga);
  yield takeEvery(
    Actions.GET_FLOOR_MAP_SETTINGS_FILE_URL.REQUEST,
    getFloorMapSettingFileURL
  );
  yield takeEvery(
    Actions.GET_FLOOR_OPERATION_LOGS.REQUEST,
    getFloorOperationLogsSaga
  );
}

export default function* rootSaga() {
  yield all([fork(watchFloorsSagas)]);
}
