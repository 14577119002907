import {
  Alert,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomForm from "src/components/CustomForm";
import CustomNote from "src/components/CustomNote";
import {
  MAX_NUM_REGISTERED_FLOORS,
  NEW_FLOOR_MAP_SETTING_MODE,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { createFloor } from "src/store/actions";
import MaxLightSensorNumberWarningTooltip from "../components/MaxLightSensorNumberWarningTooltip";
import Survey from "../components/Survey";
import StepControlButtons from "./components/StepControlButtons/StepControlButtons";
import NewFloorConfirmModal from "./components/NewFloorConfirmModal";

const InitFloorInfo = ({
  facilityId,
  newDeployment,
  updateNewDeploymentLocal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false);
  const { floors, loading: isLoadingFloorData } = useSelector(
    (state) => state.Floors
  );

  const isManualMode = Form.useWatch("isManualMode", form);

  const onGoBack = () => {
    navigate(-1);
  };

  const onFloorCreatedSuccess = () => {
    Modal.success({
      title: TextConstants.PreRegistrationCompletedConfirmModal.Title,
      content:
        TextConstants.PreRegistrationCompletedConfirmModal.ConfirmContent,
      afterClose: onGoBack,
    });
  };

  const onFinish = useCallback(
    (formValues) => {
      const newFloorData = {
        name: formValues.name,
        facilityId,
        settingMode: formValues.isManualMode
          ? NEW_FLOOR_MAP_SETTING_MODE.MANUAL
          : NEW_FLOOR_MAP_SETTING_MODE.EASY,
        dimensions: [formValues.vertical, formValues.length, formValues.height],
        isDistanceBetweenLightsWithin15m:
          !!formValues.isDistanceBetweenLightsWithin15m,
        numberLights: formValues.numberLights,
        isLicoLiteSystemsNearPlannedInstallLocation:
          formValues.isLicoLiteSystemsNearPlannedInstallLocation,
      };

      if (isManualMode) {
        newFloorData["stepNo"] = 0;
        updateNewDeploymentLocal(newFloorData);
      } else {
        dispatch(createFloor(newFloorData, onFloorCreatedSuccess));
      }
    },
    [dispatch, facilityId, onFloorCreatedSuccess]
  );

  const formInitValues = useMemo(() => {
    if (newDeployment && newDeployment.facilityId === facilityId) {
      return {
        name: newDeployment.name || "",
        vertical: newDeployment.dimensions?.[0],
        length: newDeployment.dimensions?.[1],
        height: newDeployment.dimensions?.[2],
        isManualMode: newDeployment.settingMode,
        isDistanceBetweenLightsWithin15m:
          newDeployment.isDistanceBetweenLightsWithin15m,

        // start conditions
        isLicoLiteSystemsNearPlannedInstallLocation: false,
      };
    }

    return {
      name: "",
      vertical: "",
      length: "",
      height: "",
      isManualMode: false,
      isDistanceBetweenLightsWithin15m: false,

      // start conditions
      isLicoLiteSystemsNearPlannedInstallLocation: false,
    };
  }, [newDeployment, facilityId]);

  const onGoNext = useCallback(async () => {
    try {
      await form.validateFields();
      setIsShowConfirmPopup(true);
    } catch (e) {
      return;
    }
  }, [form]);

  const onNewFloorConfirmOk = () => {
    setIsShowConfirmPopup(false);
    form.submit();
  };

  const isHaveReachedFloorSubscriptionLimit = useMemo(() => {
    return (floors || []).length >= MAX_NUM_REGISTERED_FLOORS;
  }, [floors]);

  return (
    <React.Fragment>
      <Row gutter={[24, 10]}>
        <Col xs={{ span: 24 }} style={{ marginBottom: 20 }}>
          <CustomNote>{TextConstants.NewFloorMapSettings.Note}</CustomNote>
          {isHaveReachedFloorSubscriptionLimit && (
            <Alert
              message={`${
                TextConstants.Common.MaxFloorRegisteredInvalidMessage
              }${TextConstants.Common.InvalidMaxNumberMessage.replace(
                "{num}",
                MAX_NUM_REGISTERED_FLOORS
              )}`}
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          {TextConstants.FacilityFloors.RegisterFloor}
          <br />
          {TextConstants.FacilityFloors.PleaseEnterFloorInformation}
        </Col>
      </Row>
      <CustomForm
        form={form}
        initialValues={formInitValues}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label htmlFor="floorName">
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.FloorName}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  validator: (_, value) =>
                    value.length > 0 && !value.trim()
                      ? Promise.reject(
                          new Error(TextConstants.Common.ThisFieldIsRequired)
                        )
                      : Promise.resolve(),
                },
                {
                  max: 255,
                  message: TextConstants.Common.MaxLengthWarningMessage.replace(
                    "{max}",
                    "255"
                  ),
                },
              ]}
            >
              <Input
                autoFocus
                id="floorName"
                placeholder={TextConstants.FacilityFloors.FloorNameExample}
                style={{ maxWidth: 350 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label>
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.FloorSize}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Vertical}
                  htmlFor="floorWidth"
                >
                  <Form.Item
                    noStyle
                    name="vertical"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        max: 500,
                        message:
                          TextConstants.Common.MaxMinNumberValidateMessage.replace(
                            "{min}",
                            1
                          ).replace("{max}", 500),
                      },
                    ]}
                  >
                    <InputNumber id="floorWidth" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Length}
                  htmlFor="floorLength"
                >
                  <Form.Item
                    noStyle
                    name="length"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        max: 500,
                        message:
                          TextConstants.Common.MaxMinNumberValidateMessage.replace(
                            "{min}",
                            1
                          ).replace("{max}", 500),
                      },
                    ]}
                  >
                    <InputNumber id="floorLength" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Height}
                  htmlFor="floorHeight"
                >
                  <Form.Item
                    name="height"
                    noStyle
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        message:
                          TextConstants.Common.InvalidMinNumberMessage.replace(
                            "{num}",
                            1
                          ),
                      },

                      {
                        type: "number",
                        max: 15,
                        message:
                          TextConstants.NewFloorMapSettings
                            .ExceededMaxHeightErrorMessage,
                      },
                    ]}
                  >
                    <InputNumber id="floorHeight" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 0]} style={{ display: "none" }}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}></Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              valuePropName="checked"
              name="isManualMode"
              style={{ float: "left" }}
            >
              <Checkbox id="isManualMode">
                <Typography>
                  <Typography.Paragraph>
                    <pre>
                      {TextConstants.FacilityFloors.ClickHereToRegisterDrawings}
                      <br />
                      {
                        TextConstants.FacilityFloors
                          .RegisterTheLightingTypeAndInstallationLocation
                      }
                      <br />
                      {TextConstants.FacilityFloors.ItIsEasierToManage}
                    </pre>
                  </Typography.Paragraph>
                </Typography>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label>
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.NewFloorMapSettings.CheckingInstallationConditions}
              ：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              style={{ float: "left", marginTop: -4 }}
              valuePropName="checked"
              name="isDistanceBetweenLightsWithin15m"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(TextConstants.Common.ThisFieldIsRequired)
                        ),
                },
              ]}
            >
              <Checkbox
                style={{ marginLeft: 4, marginRight: 10 }}
                id="isDistanceBetweenLightsWithin15m"
              >
                {
                  TextConstants.FacilityFloors
                    .IsTheDistanceBetweenLightsWithin15m
                }
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label htmlFor="numberLights">
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.TotalLightsLabel}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              name="numberLights"
              rules={[
                {
                  required: true,
                },
                {
                  type: "number",
                  min: 1,
                  max: 100,
                  message: (
                    <>
                      {TextConstants.Common.MaxMinNumberValidateMessage.replace(
                        "{min}",
                        "1"
                      ).replace("{max}", "100")}
                      {<MaxLightSensorNumberWarningTooltip />}
                    </>
                  ),
                },
                {
                  validator: (_, value) => {
                    if (!value || Number.isInteger(Number(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        TextConstants.Common.MaxMinNumberValidateMessage.replace(
                          "{min}",
                          "1"
                        ).replace("{max}", "200")
                      )
                    );
                  },
                },
              ]}
            >
              <InputNumber id="numberLights" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Survey form={form} />
        </Row>
      </CustomForm>
      <StepControlButtons
        backLabel={TextConstants.Common.Cancel}
        onGoBack={onGoBack}
        isShowBackIcon={false}
        isDisabledNext={
          isLoadingFloorData || isHaveReachedFloorSubscriptionLimit
        }
        onGoNext={onGoNext}
        isShowNextIcon={isManualMode}
        nextLabel={!isManualMode ? TextConstants.Common.Registration : ""}
      />
      <NewFloorConfirmModal
        isModalOpen={isShowConfirmPopup}
        handleOk={onNewFloorConfirmOk}
        handleCancel={() => {
          setIsShowConfirmPopup(false);
        }}
      />
    </React.Fragment>
  );
};

export default InitFloorInfo;
