import { SearchOutlined } from "@ant-design/icons";
import { Col, Empty, Input, Row, Space, Table, Typography } from "antd";
import React, { useMemo, useState } from "react";
import TextConstants from "src/constants/TextConstants";
import "./styles.css";

let searchChangeTimeout = null;

const BorderTable = ({
  loading,
  columns,
  dataSource,
  rowKey = null,
  showSearch = false,
  searchableCols = [],
  header = null,
  searchInputPlaceHolder = null,
  showPagination = true,
  minHeight=400,
}) => {
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  // Update the default value is empty for columns
  const dataSourceUpdated = useMemo(() => {
    if (!columns || columns.length === 0) return [];

    const columnKeys = columns.map((columnConfig) => {
      return columnConfig.dataIndex;
    });

    return dataSource.map((data) => {
      const dataCopied = { ...data };
      columnKeys.forEach((columnKey) => {
        dataCopied[columnKey] = dataCopied[columnKey] || "";
      });
      return dataCopied;
    });
  }, [dataSource, columns]);

  const searchableColumns = useMemo(() => {
    if (searchableCols && searchableCols.length > 0) {
      return searchableCols;
    }

    const sColumns = [];
    columns.forEach((c) => {
      const canSearch = "canSearch" in c;
      if (!canSearch || (canSearch && c["canSearch"])) {
        sColumns.push(c.key);
      }
    });
    return sColumns;
  }, [columns, searchableCols]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    if (searchChangeTimeout) {
      clearTimeout(searchChangeTimeout);
    }
    searchChangeTimeout = setTimeout(() => {
      setSearchValue(e.target.value);
    }, 700);
  };

  const filteredData = useMemo(() => {
    if (!searchValue || !dataSourceUpdated) {
      return dataSourceUpdated;
    }

    const searchString = searchValue.toLowerCase();
    const matchSearch = (val) =>
      String(val).toLowerCase().includes(searchString);

    const items = dataSourceUpdated.filter((item) => {
      return !!searchableColumns.find((key) => matchSearch(item[key]));
    });

    return items;
  }, [dataSourceUpdated, searchValue, searchableColumns]);

  const customProps = useMemo(() => {
    const props = {};

    let searchComponent = null;
    if (showSearch) {
      searchComponent = (
        <>
          <label htmlFor="search">
            <Typography.Text strong>
              {TextConstants.Common.Search}
            </Typography.Text>
          </label>
          <Input
            id="search"
            value={search}
            onChange={handleSearchChange}
            placeholder={searchInputPlaceHolder || TextConstants.Common.Input}
            allowClear
            className="search-bord-rad"
            size="small"
            prefix={<SearchOutlined style={{ color: "#00ACA8" }} />}
          />
        </>
      );
    }

    if (searchComponent || header) {
      props["title"] = () => (
        <>
          <Row gutter={[24, 16]}>
            <Col xs={{ span: 24 }} xl={{ span: 16 }}>
              {header && <Space>{header}</Space>}
            </Col>
            <Col xs={{ span: 24 }} xl={{ span: 8 }} className="d-flex flex-end">
              <Space direction="horizontal" size="small" wrap align="center">
                {searchComponent}
              </Space>
            </Col>
          </Row>
        </>
      );
    }

    return props;
  }, [header, showSearch, search]);

  return (
    <React.Fragment>
      <Table
        size="small"
        loading={loading}
        columns={columns}
        dataSource={filteredData}
        rowKey={rowKey}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="未登録" />
          ),
        }}
        pagination={{
          size: "default",
          showSizeChanger: true,
          defaultPageSize: 20,
          locale: {
            items_per_page: "",
          },
          position: [showPagination ? "bottom" : "none"],
        }}
        showSorterTooltip={false}
        bordered
        {...customProps}
        style={{
          minHeight,
        }}
      />
    </React.Fragment>
  );
};

export default BorderTable;
