import { DownloadOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Col, Row, Space, Typography } from "antd";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "src/components/AddButton";
import BorderTable from "src/components/BorderTable";
import CompanyFacilitiesFilter from "src/components/CompanyFacilitiesFilter/CompanyFacilitiesFilter";
import CustomCard from "src/components/CustomCard/CustomCard";
import FloorStatus from "src/components/FloorStatus";
import {
  FLOOR_SETTINGS_MODE_LABEL,
  FLOOR_SETUP_STATUS,
  SENSOR_OPERATION_METHOD_LABEL,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import {
  getFloorSetupStt,
  isFloorAlreadySetup,
} from "src/pages/facilities/Facility";
import { PATHS } from "src/routes";
import { deleteFloor, getFloors } from "src/store/actions";

const downloadFloorSettings = (
  companyName = "-",
  facilityName = "-",
  record = {}
) => {
  const doc = new jsPDF();
  doc.setFontSize(14);
  // doc.text(
  //   `${TextConstants.Common.CompanyName}${TextConstants.JPCommonCharacters.Colon}${companyName}`,
  //   10,
  //   10
  // );
  // doc.text(
  //   `${TextConstants.Facility.FacilityName}${TextConstants.JPCommonCharacters.Colon}${facilityName}`,
  //   10,
  //   20
  // );
  // doc.line(10, 30, 40, 30);
  // doc.setFontSize(12);
  // doc.text(
  //   `${TextConstants.FacilityFloors.FloorName}${TextConstants.JPCommonCharacters.Colon}${record.name}`,
  //   10,
  //   40
  // );
  // doc.text(
  //   `${TextConstants.FacilityFloors.IsTheDistanceBetweenLightsWithin15m}${
  //     TextConstants.JPCommonCharacters.Colon
  //   }${
  //     record.isDistanceBetweenLightsWithin15m
  //       ? TextConstants.Common.Yes
  //       : TextConstants.Common.No
  //   }`,
  //   10,
  //   50
  // );
  // doc.text(
  //   `${TextConstants.Common.Status}${TextConstants.JPCommonCharacters.Colon}${
  //     record.registered
  //       ? TextConstants.FacilityFloors.FloorStatus.AlreadySetup
  //       : TextConstants.FacilityFloors.FloorStatus.WaitingForSettings
  //   }`,
  //   10,
  //   60
  // );
  // doc.text(
  //   `${TextConstants.FacilityFloors.FloorSize}${
  //     TextConstants.JPCommonCharacters.LeftParenthesis
  //   }${TextConstants.FacilityFloors.Vertical} x ${
  //     TextConstants.FacilityFloors.Length
  //   } x ${TextConstants.FacilityFloors.Height}${
  //     TextConstants.JPCommonCharacters.LeftParenthesis
  //   }${TextConstants.JPCommonCharacters.Colon}${record.dimensions.join(
  //     TextConstants.JPCommonCharacters.Comma
  //   )}`,
  //   10,
  //   70
  // );
  // doc.text(
  //   `${TextConstants.Common.CreatedAt}${
  //     TextConstants.JPCommonCharacters.Colon
  //   }${dayjs(record.createdAt).format("YYYY/MM/DD HH:mm:ss")}`,
  //   10,
  //   80
  // );
  doc.text(`Company Name: ${companyName}`, 10, 10);
  doc.text(`Facility Name: ${facilityName}`, 10, 20);
  doc.line(10, 30, 40, 30);
  doc.setFontSize(12);
  doc.text(`Floor Name: ${record.name}`, 10, 40);
  doc.text(
    `Is The Distance Between Lights Within 15m: ${
      record.isDistanceBetweenLightsWithin15m ? "Yes" : "No"
    }`,
    10,
    50
  );
  doc.text(
    `Status: ${record.bmId ? "Already setup" : "Waiting For Settings"}`,
    10,
    60
  );
  doc.text(
    `Floor Size (Vertical x Length x Height): ${record.dimensions.join(", ")}`,
    10,
    70
  );
  doc.text(
    `Created At: ${dayjs(record.createdAt).format("YYYY/MM/DD HH:mm:ss")}`,
    10,
    80
  );
  doc.save(`Floor-setting-${record.floorId}.pdf`);
};

const columns = [
  {
    title: TextConstants.Common.Name,
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: "15%",
    defaultSortOrder: "ascend", // Set default sorting order
  },
  {
    title: TextConstants.Common.Mode,
    dataIndex: "settingModeName",
    key: "settingModeName",
    sorter: (a, b) => a.settingModeName.localeCompare(b.settingModeName),
    width: "10%",
  },
  {
    title: TextConstants.Common.OperationMethod,
    dataIndex: "operationMethodName",
    key: "operationMethodName",
    width: "10%",
  },
  {
    title: TextConstants.Common.Status,
    dataIndex: "statusLabel",
    key: "statusLabel",
    width: "10%",
    sorter: (a, b) => a.statusLabel.localeCompare(b.statusLabel),
  },
  {
    title: "BM Id",
    dataIndex: "bmId",
    key: "bmId",
    width: "10%",
    render: (val) => {
      return val || "-";
    },
  },
  {
    title: TextConstants.Common.BMFWVersion,
    dataIndex: "bmFWVersion",
    key: "bmFWVersion",
    width: "10%",
  },
  {
    title: TextConstants.Common.LightFWVersion,
    dataIndex: "lightFWVersion",
    key: "lightFWVersion",
    width: "10%",
  },
  {
    title: TextConstants.Common.CreatedAt,
    dataIndex: "createdAt",
    key: "createdAt",
    width: "10%",
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
];

const Floors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyIdSelected, setCompanyIdSelected] = useState();
  const [facilityIdSelected, setFacilityIdSelected] = useState();

  const { companyNameMappedByIds } = useSelector((state) => state.Companies);
  const { facilityNameMappedByIds } = useSelector((state) => state.Facilities);
  const { floors, loading: isLoadingFloorData } = useSelector(
    (state) => state.Floors
  );

  const floorsUpdated = useMemo(() => {
    if (facilityIdSelected) {
      return floors.map((floor) => {
        const _setupStt = getFloorSetupStt(floor);
        return {
          ...floor,
          bmId: floor.bmId || floor.v2Data?.bmId,
          lightFWVersion: floor.lightFWVersion || "-",
          bmFWVersion: floor.bmFWVersion || "-",
          settingModeName: FLOOR_SETTINGS_MODE_LABEL[floor.settingMode],
          operationMethodName:
            SENSOR_OPERATION_METHOD_LABEL[floor.operationMethod] || "--",
          createdAt: dayjs(floor.createdAt).format("YYYY/MM/DD HH:mm:ss"),
          _setupStt,
          statusLabel:
            _setupStt === FLOOR_SETUP_STATUS.UNCONFIGURED
              ? TextConstants.FacilityFloors.FloorStatus.WaitingForSettings
              : _setupStt === FLOOR_SETUP_STATUS.CONFIGURING
              ? TextConstants.FacilityFloors.FloorStatus.SettingUp
              : TextConstants.FacilityFloors.FloorStatus.AlreadySetup,
        };
      });
    }

    return [];
  }, [facilityIdSelected, floors]);

  const onFacilityChange = useCallback(
    (facilityId) => {
      setFacilityIdSelected(facilityId);
      if (facilityId) {
        dispatch(getFloors(facilityId));
      }
    },
    [dispatch, getFloors, setFacilityIdSelected]
  );

  const onCompanyChange = (companyId) => {
    setCompanyIdSelected(companyId);
  };

  const moveToEditFloorScreen = useCallback(
    (floorId) => {
      navigate(
        PATHS.EDIT_FACILITY_FLOOR.replace(
          ":facilityId",
          facilityIdSelected
        ).replace(":floorId", floorId)
      );
    },
    [facilityIdSelected]
  );

  const handleDeleteFloor = (floorId) => {
    dispatch(
      deleteFloor({ facilityId: facilityIdSelected, floorId }, () => {
        dispatch(getFloors(facilityIdSelected));
      })
    );
  };

  const onDownloadFloorSettings = useCallback(
    (record) => {
      const companyNameSelected =
        companyNameMappedByIds[companyIdSelected] || "-";
      const facilityNameSelected =
        facilityNameMappedByIds[facilityIdSelected] || "-";
      downloadFloorSettings(companyNameSelected, facilityNameSelected, record);
    },
    [companyNameMappedByIds, companyIdSelected, facilityIdSelected]
  );

  const moveToBMLiteConfigurationPage = (facilityId, floorId) => {
    navigate(
      PATHS.ADMIN_FLOOR_BM_CONFIGURATION.replace(
        ":facilityId",
        facilityId
      ).replace(":floorId", floorId)
    );
  };

  const columnsUpdated = useMemo(() => {
    const columnsCopied = columns.map((c) => {
      if (c.key === "statusLabel") {
        c.render = (_, record) => {
          return (
            <Space>
              {<FloorStatus status={record._setupStt} />}
              {record._setupStt === FLOOR_SETUP_STATUS.CONFIGURED && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    moveToBMLiteConfigurationPage(
                      record.facilityId,
                      record.floorId
                    );
                  }}
                >
                  {TextConstants.Common.ViewConfiguration}
                </Button>
              )}
            </Space>
          );
        };
      }
      return c;
    });
    columnsCopied.push({
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      render: (_, record) => (
        <Row justify="center" gutter={[8, 6]}>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              size="small"
              onClick={() => {
                onDownloadFloorSettings(record);
              }}
            >
              {TextConstants.Common.DetailOfSettings}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => moveToEditFloorScreen(record.floorId)}
            >
              {TextConstants.Common.Edit}
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title={TextConstants.Common.ConfirmDeleteMessage.replace(
                "{item}",
                TextConstants.Common.Floor
              )}
              onConfirm={() => handleDeleteFloor(record.floorId)}
              okText={TextConstants.Common.Delete}
              cancelText={TextConstants.Common.Cancel}
              placement="topRight"
            >
              <Button size="small" type="danger">
                {TextConstants.Common.Delete}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    });
    return columnsCopied;
  }, [columns, facilityIdSelected]);

  const handleGoToCreateFloorPage = () => {
    navigate(
      PATHS.NEW_FACILITY_FLOOR.replace(":facilityId", facilityIdSelected)
    );
  };

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xl={{ span: 24 }}>
          <CustomCard
            bordered={true}
            className="common-card tablespace"
            title={TextConstants.Pages.FloorPlan}
          >
            <BorderTable
              rowKey={"floorId"}
              showSearch
              loading={isLoadingFloorData}
              columns={columnsUpdated}
              dataSource={floorsUpdated}
              header={
                <React.Fragment>
                  <CompanyFacilitiesFilter
                    onCompanyChange={onCompanyChange}
                    onFacilityChange={onFacilityChange}
                  />
                  <AddButton
                    disabled={!facilityIdSelected}
                    onClick={handleGoToCreateFloorPage}
                    label="フロア作成"
                  />
                </React.Fragment>
              }
            />
          </CustomCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Floors;
