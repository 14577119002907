import { Button } from "antd";
import TextConstants from "src/constants/TextConstants";

const AddButton = ({
  label = "",
  onClick = null,
  style = {},
  type = "primary",
  htmlType = "button",
  disabled = false,
  loading = false,
}) => {
  return (
    <Button
      type={type}
      htmlType={htmlType}
      onClick={onClick ? onClick : () => {}}
      style={{ ...{ minWidth: 120,  }, ...style }}
      disabled={disabled}
      loading={loading}
    >
      <p><span style={{paddingRight: 5}}>+</span>{label ? label : TextConstants.Common.Add}</p>
    </Button>
  );
};

export default AddButton;
