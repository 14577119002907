import {
  FEEDBACK_TYPE,
  COLOR_SLIDER_GRADIENT,
  COLOR_VALUES,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";

export const validateInteger = (_, value) => {
  if (!value || Number.isInteger(Number(value))) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter an integer"));
};

export const getFeedbackTypeLabelByKey = (fbKey) => {
  switch (fbKey) {
    case FEEDBACK_TYPE.BUGS_REPORT:
      return TextConstants.SendFeedback.IssueTypeBugsReport;
    case FEEDBACK_TYPE.FEEDBACK:
      return TextConstants.SendFeedback.IssueTypeFeedback;

    default:
      return "N/A";
  }
};

export const hashCodeStrToNum = (str) => {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const getColorHexFromSliderValue = (colorValue) => {
  /* return the color from slider approximately */
  const indexFactor = COLOR_VALUES.max / COLOR_SLIDER_GRADIENT.length;
  const roundIndex = Math.round(colorValue / indexFactor);

  return COLOR_SLIDER_GRADIENT[roundIndex];
};

// Floor data v1 and v2
export const SWITCH_STEP_CONFIG = {
  on: "ON",
  off: "OFF",
  bright: "BRIGHT",
  dark: "DARK",
  colorStepUp: "COLOR_STEP_UP",
  colorStepDown: "COLOR_STEP_DOWN",
};

export const defaultLightingStatup = {
  mode: 0,
  brightness: 50,
  color: 20,
  fade: 0,
};

const defaultSensor = {
  mode: 0,
  sensivity: 5,
  dimmingRate: 100,
  standByDimmingRate: 0,
  retentionTime: {
    minutes: 0,
    seconds: 15,
  },
  brightness: 9,
  minDimmingRate: 0,
};

export const defaultScheduleCard = {
  slotID: null, // new
  days: [],
  startHour: "00",
  startMin: "00",
  groups: [],
};

export const defaultGroup = {
  groupID: null,
  label: "", // Name of the group
  sensor: defaultSensor, // Sensor settings
  bootConfig: defaultLightingStatup, // Lighting at startup config
};

export const defaultFloor = {
  bmId: null,
  floorId: "",
  facilityId: "",
  name: "",
  encryptionKey: "",
  numberLights: 0,
  groups: [],
  lights: [],
  maxBrightness: 100,
  dimmingStep: 20,
  switches: [],
  updatedTime: null, // Unix time in seconds (4 bytes)
  dataFormatVer: 2, // Version of the data structure format
  sensorsEnabled: false,
  schedulesEnabled: false,
  configID: 0,
  schedules: [],
};

const setHighBit = (value) => {
  return (value + 50) | 0x80;
};

const parseSwitchBrightnessValue = (brightnessConfig, step) => {
  let brightnessValue = null;
  switch (brightnessConfig) {
    case SWITCH_STEP_CONFIG.off:
      brightnessValue = 0;
      break;
    case SWITCH_STEP_CONFIG.on:
      brightnessValue = 101;
      break;
    case SWITCH_STEP_CONFIG.bright:
      brightnessValue = setHighBit(step);
      break;
    case SWITCH_STEP_CONFIG.dark:
      brightnessValue = setHighBit(step * -1);
      break;
    default:
      brightnessValue = 0xff;
      break;
  }

  return brightnessValue;
};

const parseSwitchColorValue = (colorConfig) => {
  let colorValue = null;

  if (colorConfig === SWITCH_STEP_CONFIG.colorStepDown) {
    colorValue = 0x81;
  } else if (colorConfig === SWITCH_STEP_CONFIG.colorStepUp) {
    colorValue = 0x82;
  } else {
    colorValue = 0xff;
  }

  return colorValue;
};

const serializeRemoteButtonsForSaving = (buttonsData, dimmingStep) => {
  return buttonsData
    .filter((button) => button.configured)
    .map((button) => {
      return {
        buttonID: button.buttonID,
        mode: button.mode,
        configType: button.configType,
        settings:
          button.mode === 0
            ? [
                {
                  groupID: button.simpleModeGroupId,
                  brightness: parseSwitchBrightnessValue(
                    button.simpleModeBrightness,
                    dimmingStep
                  ),
                  color: parseSwitchColorValue(button.simpleModeColor),
                },
              ]
            : button.groupSettings
                .filter((setting) => setting.activated)
                .map((setting) => {
                  return {
                    groupID: setting.groupID,
                    brightness: setting.brightness,
                    color: setting.color,
                  };
                }),
      };
    });
};

export const patchFloorDataToV2 = (v1FloorData) => {
  return {
    bmId: v1FloorData.bmId ?? defaultFloor.bmId,
    floorId: v1FloorData.floorId,
    facilityId: v1FloorData.facilityId,
    name: v1FloorData.name,
    encryptionKey: v1FloorData.encryptionKey ?? defaultFloor.encryptionKey,
    numberLights: v1FloorData.numberLights,
    maxBrightness:
      v1FloorData.brightnessUpperLimit ?? defaultFloor.maxBrightness,
    dimmingStep:
      v1FloorData.switchStepDimmingConfig ?? defaultFloor.dimmingStep,
    updatedTime: defaultFloor.updatedTime,
    configID: defaultFloor.configID,
    groups:
      v1FloorData.groups?.map((group) => ({
        groupID: group.id,
        label: group.label,
        sensor: group.sensor,
        bootConfig: group.lightingStartupConfig,
      })) ?? defaultFloor.groups,
    lights:
      v1FloorData.lights?.map((light) => ({
        id: light.id,
        groupId: light.groupId,
        devType: light.type,
        mac: "",
      })) ?? defaultFloor.lights,
    switches:
      v1FloorData.switches?.map((swt) => ({
        name: swt.name,
        mac: swt.mac,
        buttons: serializeRemoteButtonsForSaving(
          swt.buttons,
          v1FloorData.switchStepDimmingConfig
        ),
        remoteType: swt.type,
      })) ?? defaultFloor.switches,
    schedules:
      v1FloorData.groups
        ?.flatMap((group) =>
          group.schedules
            .filter((schedule) => schedule.registered)
            .map((schedule) => ({
              slotID: schedule.slotId,
              days: schedule.daysOfTheWeek,
              startHour: schedule.timeSchedules[0].startHour,
              startMin: schedule.timeSchedules[0].startMin,
              groups: schedule.timeSchedules
                .filter((timeSchedule) => timeSchedule.registered)
                .map((timeSchedule) => ({
                  groupID: group.networkId,
                  brightness: timeSchedule.brightness,
                  color: timeSchedule.color,
                })),
            }))
        )
        .filter((schedule) => schedule.groups.length > 0) ??
      defaultFloor.schedules,
  };
};

export const parseSwitchBrightnessValueToUIReadable = (value) => {
  let brightnessValue = null;

  switch (true) {
    case value === 0:
      brightnessValue = SWITCH_STEP_CONFIG.off;
      break;
    case value === 101:
      brightnessValue = SWITCH_STEP_CONFIG.on;
      break;
    case value >= 188 && value <= 228:
      brightnessValue = SWITCH_STEP_CONFIG.bright;
      break;
    case value >= 128 && value <= 168:
      brightnessValue = SWITCH_STEP_CONFIG.dark;
      break;
    default:
      brightnessValue = null;
      break;
  }

  return brightnessValue;
};

export const parseSwitchColorValueToUIReadable = (value) => {
  let colorValue = null;

  if (value === 0x81) {
    colorValue = SWITCH_STEP_CONFIG.colorStepDown;
  } else if (value === 0x82) {
    colorValue = SWITCH_STEP_CONFIG.colorStepUp;
  } else {
    colorValue = null;
  }

  return colorValue;
};

export const parseSwitchConfigValueToText = (val, dimmingStep) => {
  switch (val) {
    case 0:
      return "明るさ";
    case SWITCH_STEP_CONFIG.off:
      return "消灯";
    case SWITCH_STEP_CONFIG.on:
      return "点灯";
    case SWITCH_STEP_CONFIG.bright:
      return `${dimmingStep}%明るく`;
    case SWITCH_STEP_CONFIG.dark:
      return `${dimmingStep}%暗く`;
    case 1:
      return "明るさ";
    case SWITCH_STEP_CONFIG.colorStepDown:
      return "1段階暖色に";
    case SWITCH_STEP_CONFIG.colorStepUp:
      return "1段階昼光色に";

    default:
      return "-";
  }
};
