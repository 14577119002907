import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Col, Layout, Row, Spin, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import { JWT_REGEX } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { parseJwt } from "src/helpers/AuthHelper";
import { Notification } from "src/helpers/Notification";
import { PATHS } from "src/routes";
import { CONFIRM_EMAIL_ADDRESS, confirmEmailAddress } from "src/store/actions";
const { Content } = Layout;

const ConfirmEmailAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { loading, type } = useSelector((state) => state.Auth);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the user agent corresponds to a mobile device
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return true;
      }
      return false;
    };

    setIsMobile(checkIfMobile());
  }, []);

  useEffect(() => {
    // get the invitation token
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (!token || !JWT_REGEX.test(token)) redirectToLoginPage();
    // parse the token payload and get the username
    const parseToken = parseJwt(token);

    if (parseToken.exp) {
      const expTime = (parseToken.exp || 0) * 1000;
      if (dayjs().isAfter(dayjs(expTime))) {
        Notification.error(TextConstants.Common.ExpiredLink);
        redirectToLoginPage();
      }
    }

    dispatch(confirmEmailAddress({ token }));
  }, [search, confirmEmailAddress, dispatch]);

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  const handleOpenApp = () => {
    window.location.href = "liconexlite://login";
  };

  const isConfirmEmailSuccessFul = useMemo(() => {
    return type === CONFIRM_EMAIL_ADDRESS.SUCCESS;
  }, [type]);

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center mt-20">
              {isConfirmEmailSuccessFul && (
                <div>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    メールアドレスは正常に認証されました。
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginLeft: 10 }}
                    />
                  </span>
                  <br />
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    でログインできます。
                  </span>
                  <div style={{ marginTop: 20 }} className="d-flex flex-column">
                    <div hidden={isMobile}>
                      <Typography.Link onClick={redirectToLoginPage}>
                        {`${window.location.hostname}${PATHS.LOGIN}`}
                      </Typography.Link>
                    </div>
                    <div hidden={!isMobile}>
                      <Button onClick={handleOpenApp}>
                        LiCONEX LiTEアプリを開く
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {!isConfirmEmailSuccessFul && (
                <>
                  LiCONEX LiTEにメールアドレスを確認しています。
                  {loading && <Spin style={{ marginLeft: 10 }} />}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ConfirmEmailAddress;
