import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

import TextConstants from "src/constants/TextConstants";

const NewFloorConfirmModal = ({
  isModalOpen = false,
  handleOk = () => {},
  handleCancel = () => {},
}) => {
  return (
    <Modal
      title={
        <>
          <ExclamationCircleOutlined style={{ marginRight: 8 }} />
          {TextConstants.Common.ExecutionConfirmation}
        </>
      }
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      okText={TextConstants.Common.OK}
      cancelText={TextConstants.Common.Cancel}
    >
      <p>{TextConstants.Common.ConfirmCreateNewFloorMessage1}</p>
      <p>{TextConstants.Common.ConfirmCreateNewFloorMessage2}</p>
    </Modal>
  );
};

export default NewFloorConfirmModal;
